import React, { useEffect, useState } from "react";
import _ from "lodash";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import RecommendationItems from "./recommendationItems";
import { addToCart } from "../../redux/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import getUpsellingItems from "../../utils/getUpsellingitems";
import useBrandConfig from "../../hooks/useBrandConfig";
import { CustomizeModal } from "../home/CustomizeModal";
import { setOpenConfetti } from "../../redux/appSlice";

function Recommendation({ responsive, supaProducts, onDispatchCartTax }) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const { config, recommendations } = useBrandConfig();
  const { posStoreStockList, posMenuButton, posFullItemList } = useSelector(
    (state) => state.pos
  );

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);

  // const placeOrder = (dishDetail) => {
  //   if (dishDetail.itemmaster_menutype_grpdtls === "") {
  //     dispatcher(
  //       addToCart({
  //         id: dishDetail.item_no,
  //         addOnValues: [],
  //         item: dishDetail,
  //         qty: 1,
  //         price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
  //       })
  //     );
  //     toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 1000,
  //     });
  //     // navigate("/cart");
  //   } else {
  //     navigate("/customize-dish", {
  //       state: { item: dishDetail },
  //     });
  //   }
  //   // navigate("/customize-dish", {
  //   //   state: { item: dishDetail },
  //   // });
  // };

  const placeOrder = async (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      dispatcher(
        addToCart({
          id: dishDetail.item_no,
          addOnValues: [],
          item: dishDetail,
          qty: 1,
          price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
        })
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      if (config?.enableConfetti) {
        dispatcher(setOpenConfetti(true));
      }
      const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
        dishDetail.category_code,
        dishDetail.item_category
      );
      if (hasUpsellingItems) {
        setUpsellingItems(upsellItems);
        setShowUpsellingModal(true);
      }
    } else {
      const customDetails = {
        item: dishDetail,
      };

      if (config?.usingCustomizePage) {
        navigate("/customize-dish", {
          state: customDetails,
        });
      } else {
        setShowModalData(customDetails);
        setShowCustomizeModal(true);
      }
    }
  };

  const [gochiMenuItems, setGochiMenuItems] = useState([]);

  const itemsListAndCategory = () => {
    const resultParse = JSON.parse(posStoreStockList);

    let categoryCodes = [];
    for (const category of resultParse.filter(
      (category) => category.avl_type === "C"
    )) {
      categoryCodes.push(category.item_category);
    }
    let resultItemsParsed = resultParse.filter(
      (category) => category.avl_type === "I"
    );
    menuButtonFunction(
      categoryCodes,
      resultItemsParsed,
      resultParse?.filter(
        (item) => item.is_emenu_disable === "N" && item.is_soldout === "N"
      )
    );
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    const categoryMenuButton = posMenuButton;

    let categoryMainMenuButton = categoryMenuButton.filter(
      (menuItem) => menuItem.root_category_code === "MAIN"
    );
    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      categoryMainMenuButton = categoryMainMenuButton[0];
      let categoriesIndex = categoryMainMenuButton.category || [];
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = [...categoriesIndex]
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      categoryCodes = [];
      for (const category of categoriesIndex) {
        categoryCodes.push(category.category_code);
      }
      let menuItemsArray = [];
      for (const category of categoryCodes) {
        const catItem = categoryMenuButton.filter(
          (catItems) => catItems.root_category_code === category
        );
        if (
          catItem &&
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          menuItemsArray.push(...catItem[0].items);
        }
      }
      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      ).filter(
        (item) =>
          item.is_soldout === "N" &&
          item.is_emenu_disable === "N" &&
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );

      menuItemsFunction(intersectCategory, categoryCodes, resultParse);
    }
  };

  const menuItemsFunction = (intersectCategory, categoryCodes, resultParse) => {
    const result = posFullItemList;

    const filterItemMaster = result.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = resultParse.filter(
            (itemCategory) => itemCategory.item_no === typeDtls.citem_no
          );
          const findItem = resultParse?.find(
            (record) => record?.item_no === typeDtls.citem_no
          );
          const checkingDisabledMenu =
            findItem?.is_emenu_disable === "N" && findItem?.is_soldout === "N";

          if (checkingDisabledMenu) {
            if (modifierQty && modifierQty.length > 0) {
              itemmaster_menutypedtls.push({
                ...typeDtls,
                is_soldout: modifierQty[0].is_soldout,
                is_emenu_disable: modifierQty[0].is_emenu_disable,
                is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                bal_qty: modifierQty[0].bal_qty,
              });
            } else {
              itemmaster_menutypedtls.push({ ...typeDtls });
            }
          }
        }

        const findStockedItem = resultParse?.find(
          (record) => record?.item_category === itemMaster?.item_no
        );

        if (findStockedItem) {
          stockedCheckedItem.push({
            ...findStockedItem,
            ...itemMaster,
            itemmaster_menutypedtls,
          });
        }
      }
    }
    const filterNoItemMaster = result.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );
    const combineItems = [];

    [...filterNoItemMaster, ...stockedCheckedItem].forEach((record) => {
      const findStockedItem = resultParse?.find(
        (item) => (item?.item_category || item?.item_no) === item?.item_no
      );

      if (findStockedItem) {
        combineItems.push({
          ...findStockedItem,
          ...record,
        });
      }
    });

    let intersectItems = _.intersectionBy(
      intersectCategory,
      combineItems,
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active);

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy(combineItems, "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter(
        (item) => item.is_emenu_disable === "N" && item.is_soldout === "N"
      )
      .filter((record) =>
        !recommendations?.length
          ? true
          : recommendations
              ?.map((item) => item?.id)
              ?.includes(record?.category_code)
      );

    const listItemCategory = intersectCategory?.map(
      (record) => record.item_category || record.item_no
    );
    const newResultItemsParsed = combineItems
      ?.filter(
        (record) =>
          !listItemCategory.includes(record.item_category || record.item_no)
      )
      .filter(
        (item) => item.is_emenu_disable === "N" && item.is_soldout === "N"
      )
      .filter((record) =>
        !recommendations?.length
          ? true
          : recommendations
              ?.map((item) => item?.id)
              ?.includes(record?.category_name)
      );

    if (newResultItemsParsed?.length > 0) {
      while (intersectItems?.length < 10) {
        const randomItem =
          newResultItemsParsed?.[
            parseInt(Math.random() * (newResultItemsParsed.length - 1))
          ];
        const checkingExistItem = intersectItems?.find(
          (record) => record?.item_no === randomItem?.item_no
        );

        if (!checkingExistItem) {
          intersectItems.push(randomItem);
        }
      }
    }

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );

    const sortByCategory = sortAlphabetically.sort(
      (a, b) =>
        categoryCodes.indexOf(a.category_code) -
        categoryCodes.indexOf(b.category_code)
    );

    setGochiMenuItems(sortByCategory);
  };

  useEffect(() => {
    itemsListAndCategory();
  }, []);

  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code,
      submittedItem.item_category
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Carousel
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {gochiMenuItems &&
          gochiMenuItems.map((sliderdata, index) => {
            return (
              <>
                <RecommendationItems
                  sliderdata={sliderdata}
                  index={index}
                  placeOrder={placeOrder}
                  supaProducts={supaProducts}
                  onDispatchCartTax={onDispatchCartTax}
                />
              </>
            );
          })}
      </Carousel>
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
        />
      ) : null}
    </>
  );
}

export default Recommendation;
